import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ButtonGroup,
  Modal,  
} from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANT } from "../../../routes/constant";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import EditNewsModal from "../../../components/Model/EditNewsModel";

function NewsPage() {
  const [newsList, setNewsList] = useState([]);
  const [showFullText, setShowFullText] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  const navigate = useNavigate();

  const fetchNews = async () => {
    try {
      const response = await allAPiServicesCall.getNews(
        {},
        getAuthConfig(),
        navigate
      );
      setNewsList(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToNewsDetails = (id) => {
    navigate(`/news-details/${id}`);
  };


  const confirmDelete = (id) => {
    setNewsToDelete(id);
    setShowDeleteModal(true);
  };

  const handleOnDeleteNews = async (id) => {
    if (!newsToDelete) return;
    setIsLoading(true);
    try {
      let payload = { newsId: newsToDelete };
      await allAPiServicesCall.deleteNews(
         payload ,
        getAuthConfig(),
        navigate
      );
   
      setShowDeleteModal(false)
      fetchNews();
      
    } catch (error) {
      console.log(error, ":error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchNews();
  }, []);


  return (
    <CommonLayout>
   
      <Container className="" style={{ marginBottom: "5rem" }}>
      <div className="d-flex justify-content-center align-item-center " style={{marginTop:"5rem"}}>
        <Button
          className="btn btn-default"
          variant="success"
          onClick={() => navigate(ROUTE_CONSTANT.COMMON.ADD_NEWS)}
        >
          Add News
        </Button>
      </div>
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : newsList.length === 0 ? (
          <div className="text-center">
            <h4>News Not Found</h4>
          </div>
        ) : (
          <Row className="g-4  justify-content-center">
            {newsList.map((news) => {
              const { _id, title, description, coverImg } = news;
              const truncatedText =
                description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 100) +
                "...";
              const fullText = description.replace(/<\/?[^>]+(>|$)/g, "");
              return (
                <Col key={_id} xs={12} sm={6} md={4} >
                  <Card
                    style={{
                      width: "100%",
                      maxWidth: "350px",
                      height: "100%",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={coverImg}
                      style={{ height: "200px", objectFit: "cover" }}
                    />
                    <Card.Body style={{ flex: "1 0 auto" }}>
                      <Card.Title className="text-center">{title}</Card.Title>
                      <Card.Text>
                        {showFullText[_id] ? fullText : truncatedText}
                      </Card.Text>
                    </Card.Body>
                    {/* <div className="text-center mb-3">
                      <Button
                        className="mx-2"
                        variant="primary"
                        onClick={() => navigateToNewsDetails(_id)}
                      >
                        {showFullText[_id] ? "Show Less" : "Show Details"}
                      </Button>
                      <Button
                        variant="danger"
                        className="mx-2"
                        onClick={() => handleOnDelete(_id)}
                      >
                        Delete News
                      </Button>
                    </div> */}
                    {/* <div className="text-center  d-flex  ">
                      <Button
                        className=""
                       
                        variant="primary"
                        onClick={() => navigateToNewsDetails(_id)}
                      >
                        {showFullText[_id] ? "Show Less" : "Show Details"}
                      </Button>
                      <Button
                      variant="info"
                 onClick={() => handleEditModel(news)}
                    >
                      Edit
                    </Button>
                      <Button
                        className=""
                      
                        variant="danger"
                        onClick={() => confirmDelete(_id)}
                      >
                        Delete News
                      </Button>
                    </div> */}
                    
                    <ButtonGroup className=" ">
                    <Button                      
                        variant="primary"
                        onClick={() => navigateToNewsDetails(_id)}
                      >
                        {showFullText[_id] ? "Show Less" : "Show Details"}
                      </Button>
                      
                    <Button
                      variant="info"
                      onClick={() => navigate(`/edit-news/${_id}`)}
                    >
                      Edit 
                    </Button>
                
                    <Button                      
                        variant="danger"
                        onClick={() => confirmDelete(_id)}
                      >
                        Delete News
                      </Button>
                  </ButtonGroup>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>



         {/* Delete Confirmation Modal */}
         <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this News?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleOnDeleteNews}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
}

export default NewsPage;
