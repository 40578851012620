import React, { useEffect, useState, useCallback } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import CommonLayout from "../../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { toast } from "react-toastify";

const YoutubeVideo = () => {
  const [videos, setVideos] = useState([]);
  const [addedVideos, setAddedVideos] = useState([]); // Store already added videos
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isSearching, setIsSearching] = useState(false); // Track if user is searching

  // Fetch already added videos
  const fetchVideos = useCallback(async () => {
    try {
      setLoading(true);
      const response = await allAPiServicesCall.getYoutubeVideos(
        getAuthConfig()
      );
      setAddedVideos(response.data || []); // Store added videos separately
      setVideos(response.data || []); // Show added videos initially
      setError(null);
    } catch (error) {
      setError("Failed to fetch videos.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch search results
  const fetchSearchResults = useCallback(
    async (query) => {
      if (!query.trim()) {
        setIsSearching(false);
        fetchVideos(); // If no query, show added videos
        return;
      }

      try {
        setLoading(true);
        setIsSearching(true);
        const response = await allAPiServicesCall.getSearchYoutubeVideos(
          { query },
          getAuthConfig()
        );

        setVideos(response?.data?.length > 0 ? response.data : []);
      } catch (error) {
        setError("Failed to fetch search results.");
      } finally {
        setLoading(false);
      }
    },
    [fetchVideos]
  );

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  // Debounce search input (improves performance)
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchSearchResults(searchQuery);
    }, 500);

    return () => clearTimeout(handler);
  }, [searchQuery, fetchSearchResults]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddVideo = async (video) => {
    try {
      const response = await allAPiServicesCall.addYoutubeVideo(
        {
          videoId: video.videoId,
          title: video.title,
          description: video.description,
          thumbnail: video.thumbnail,
          channelTitle: video.channelTitle,
          publishTime: video.publishTime,
        },
        getAuthConfig()
      );

      if (response.status === 201) {
        fetchVideos();
        toast.success(response.message || "Video added successfully!");
      }
    } catch (error) {
      toast.error("Failed to add video.");
    }
  };

  const handleDeleteClick = (video) => {
    setSelectedVideo(video);
    setShowDeleteModal(true);
  };

  const handleDeleteVideo = async () => {
    if (!selectedVideo) return;

    try {
      const response = await allAPiServicesCall.deleteYoutubeVideo(
        { videoId: selectedVideo._id },
        getAuthConfig()
      );

      if (response.status === 200) {
        toast.success(response.message || "Video deleted successfully!");
        fetchVideos(); // Refresh list after deletion
      }
    } catch (error) {
      toast.error("Failed to delete video.");
    } finally {
      setShowDeleteModal(false);
      setSelectedVideo(null);
    }
  };

  return (
    <CommonLayout>
      <div className="container mt-5 pt-2">
        <h2 className="text-center mt-3 mb-4">YouTube Videos</h2>

        <Form.Group controlId="searchAlbum">
          <Form.Control
            type="text"
            placeholder="Search for artists for adding to trending and recommended..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="mb-4"
          />
        </Form.Group>

        {error && <p className="text-danger text-center">{error}</p>}
        {loading && <p className="text-center">Loading videos...</p>}

        <div className="row mb-5 pb-4">
          {videos.length > 0
            ? videos.map((video) => {
                const isAdded = addedVideos.some(
                  (v) => v.videoId === video.videoId
                );

                return (
                  <div className="col-md-4 mb-4" key={video.videoId}>
                    <div className="card shadow-sm h-100">
                      <iframe
                        className="card-img-top"
                        width="100%"
                        height="200"
                        src={`https://www.youtube.com/embed/${video.videoId}`}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <div className="card-body">
                        <h5 className="card-title">{video.title}</h5>
                        <p className="text-muted">
                          <strong>Published:</strong>{" "}
                          {new Date(video.publishTime).toLocaleDateString()}
                        </p>
                        <div className="d-flex justify-content-between">
                          {/* Show Add button only for searched results */}
                          {isSearching && !isAdded && (
                            <Button
                              variant="primary"
                              onClick={() => handleAddVideo(video)}
                            >
                              Add Video
                            </Button>
                          )}

                          {/* Show Delete button only for added videos */}
                          {isAdded && (
                            <Button
                              variant="danger"
                              onClick={() => handleDeleteClick(video)}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : !loading && <p className="text-center">No videos available.</p>}
        </div>

        {/* Delete Confirmation Modal */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete{" "}
            <strong>{selectedVideo?.title}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteVideo}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </CommonLayout>
  );
};

export default YoutubeVideo;
