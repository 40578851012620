import React from "react";

const MainFooter = () => {
  return (
    
      <footer
        className="footer text-center text-lg-start bg-body-tertiary text-muted fixed-bottom"
      
      >
        <div
          className="text-center p-4"
          style={{ backgroundColor: "#ececec" }}
        >
          © 2024 Copyright{" "}
          <a className="text-reset fw-bold" href="https://lyricsweb.com/">
            Lyricsweb.com
          </a>
        </div>
      </footer>
  );
};

export default MainFooter;
