import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  InputGroup,
  CardFooter,
} from "react-bootstrap";
import CommonLayout from "../../layouts/CommonLayout";
import { allAPiServicesCall } from "../../services/apiServices";
import { getAuthConfig } from "../../services/apiUtils";
import "./RecommendedSongs.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../../pages/common/ConfirmationModal/ConfirmationModal";

const RecommendedSongs = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [recommendedSongs, setRecommendedSongs] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deletingId, setDeletingId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("");


  const fetchSongRecommendedList = async (page = 1) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getSongRecommendedList(
        { page },
        getAuthConfig()
      );
      const { success, data } = response;

      if (success && Array.isArray(data.songs)) {
        setRecommendedSongs(data.songs);
        setTotalPages(data.totalPages);
      } else {
        console.warn("No data found or data is not an array");
      }
    } catch (error) {
      console.error("Error fetching recommended songs:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchSong = async (song_name) => {
    if (!song_name.trim()) return;
    setLoading(true);
    try {
      const response = await allAPiServicesCall.getSearchSong(
        { song_name },
        getAuthConfig()
      );
      const searchList = response.data || [];
      if (searchList.length > 0) {
        setSearchResults(searchList);
      } else {
        setSearchResults([]);
        toast.info("No results found");
      }
    } catch (error) {
      console.error("Error fetching search song:", error);
      toast.error("Failed to fetch search song");
    } finally {
      setLoading(false);
    }
  };

  const addRecommendedSong = async (song_id) => {
    setLoading(true);
    try {
      const response = await allAPiServicesCall.addRecommendedSong(
        { song_id },
        getAuthConfig()
      );

      if (response && response.success) {
        toast.success("Song added to recommended list.");
      } else {
        const errorMessage = response
          ? response.message
          : "Failed to add song. Please try again.";
        toast.error("Failed to add song: " + errorMessage);
      }
    } catch (error) {
      console.error("Error adding recommended song:", error);

      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to add recommended song.";
      toast.error("Failed to add recommended song: " + errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = (songId) => {
    setDeletingId(songId);
    setShowModal(true);
  };

  const handleConfirmDelete = async (songId) => {
    setDeletingId(songId);
    setLoading(true);
    try {
      const payload = { id: songId };
      const response = await allAPiServicesCall.deleteRecommendedSong(
        payload,
        getAuthConfig()
      );

      if (response && response.status === 200) {
        setRecommendedSongs((prevSongs) =>
          prevSongs.filter((song) => song._id !== songId)
        );
        setShowModal(false)
        toast.success("Song deleted successfully!");
      } else {
        toast.error("Failed to delete song!");
      }
    } catch (error) {
      console.error("There was an error deleting the song!", error);
      toast.error("Failed to delete song!");
    } finally {
      setLoading(false);
      setDeletingId(null);
    }
  };


  useEffect(() => {
    if (!searchTerm.trim()) {
      fetchSongRecommendedList(currentPage);
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 2000);
    return () => clearTimeout(timer);
  }, [searchTerm]);


  useEffect(() => {
    if (debouncedTerm) {
      fetchSearchSong(debouncedTerm);
    }
  }, [debouncedTerm]);

  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);


  };

  return (
    <CommonLayout>
      <div className="container my-5 pb-5" >
        <h3
          style={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.8rem",
            fontWeight: "600",
            letterSpacing: "1.5px",
            textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)",
            marginBottom: "10px",
            paddingTop: "20px",
            background: "-webkit-linear-gradient(to right, #4c6ef5, #3a86ff)",
            WebkitBackgroundClip: "text",
          }}
        >RECOMMMENDED SONGS</h3>

        <div className="mb-4">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search for a song to add to recommended songs..."
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </InputGroup>
        </div>

        {loading ? (
          <div className="text-center">Loading...</div>

        ) : searchResults.length > 0 ? (
          <div>

            <h4>Search Results</h4>
            <Row className="justify-content-center">
              {searchResults.map((song, index) => (
                <Col key={index} xs={12} sm={6} md={6} lg={4} xl={3} className="mb-2">
                  <Card className="song-card">
                    <Card.Img
                      variant="top"
                      src={song.song_image_link}
                      alt={song.song_name}
                    />
                    <Card.Body>
                      <Card.Title><ReadMoreText text={song.song_name} maxLength={20} minimumLenth={9} isTitle /></Card.Title>
                      <Card.Text>
                        <strong>Artist:</strong> {song?.artist?.artist_name}
                      </Card.Text>
                    </Card.Body>
                    <CardFooter className="add-card-footer">
                      <Button
                        variant="success"
                        onClick={() => addRecommendedSong(song.song_id)}
                      >
                        Add to Recommended
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : recommendedSongs.length === 0 ? (
          <div className="text-center">No recommended songs available.</div>
        ) : (
          <div>

            <Row className="me-4">
              {recommendedSongs
                .filter((song) =>
                  song.song_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((song, index) => (
                  <Col key={index} xs={12} sm={6} md={6} lg={4} xl={3} className="mb-2">
                    <Card className="song-card">
                      <Card.Img
                        variant="top"
                        src={song.song_image_link}
                        alt={song.song_name}
                        style={{ height: "150px", objectFit: "cover" }}
                      />
                      <Card.Body>
                        <Card.Title> <ReadMoreText text={song.song_name} maxLength={20} minimumLenth={9} isTitle /></Card.Title>
                        <Card.Text>
                          <strong>Artist Name:</strong>{" "}
                          {song.artist?.artist_name}
                          <br />
                          <strong>Album Name:</strong>{" "}
                          <ReadMoreText text={song.album?.album_name || "No Album Name"} />
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="song-card-footer mx-2">
                        <Button
                          variant="success"
                          className="view-lyrics-button w-100"
                          onClick={() => navigate(`/lyrics/${song.isrc_code}`)}
                        >
                          View Lyrics
                        </Button>
                        <Button
                          variant="danger"
                          className="delete-button w-100"
                          onClick={() => handleOnDeleteClick(song._id)}
                        >
                          Delete
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        )}
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        onConfirm={() => handleConfirmDelete(deletingId)} // Pass the deletingId directly
        message="Are you sure you want to delete this song?" // Changed "album" to "song"
      />
    </CommonLayout>
  );
};

const ReadMoreText = ({ text, maxLength = 15, isTitle = false, minimumLenth = 11 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatedText =
    text.length > maxLength && !isExpanded
      ? `${text.slice(minimumLenth, maxLength)}...`
      : text;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span>
      {truncatedText}
      {text.length > maxLength && (
        <span
          style={{
            color: "blue",
            cursor: "pointer",
            marginLeft: "5px",
            fontSize: isTitle ? "0.85rem" : "0.8rem",
          }}
          onClick={toggleExpanded}
        >
          {isExpanded ? "read less" : "Read more"}
        </span>
      )}
    </span>
  );
};


export default RecommendedSongs;
