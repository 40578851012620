import { toast } from "react-toastify";
import { makeRequest } from "./apiRequests";
import { ROUTE_CONSTANT } from "../routes/constant";
import { handleApiError, setAuthToken } from "../utils/authHelpers";
import axios from "axios";
const BASE_URL = "https://lyricsweb.com/api/v1";

async function loginAdmin(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/admin/login-admin",
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      setAuthToken(data.data);
      navigate(ROUTE_CONSTANT.COMMON.DASHBOARD);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getProfileDetails(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/admin-profile`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function changePassword(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/change-password`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function forgetPassword(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/forget-password`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.AUTH.LOGIN);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function resetPassword(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/reset-password`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.AUTH.LOGIN);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
    navigate(ROUTE_CONSTANT.AUTH.FORGET_PASSWORD);
  }
}

async function getNews(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-news-list`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getNewsDetails(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-news?newsId=${payload.newsId}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.info(data.message);
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addNews(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/admin/add-news",
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.NEWS);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addNewsCoverPic(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/admin/upload-news-avatar",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteNews(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/delete-news?newsId=${payload.newsId}`,
      {},
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.NEWS);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTestimonial(payload, config, navigate) {
  try {
    const { status, data } = await makeRequest(
      "POST",
      `/admin/add-testimonial`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.TESTIMONIALS);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTestimonialProfilePic(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/admin/upload-testimonial-avatar",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getTestimonialList(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-testimonials-list`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.info(data.message);
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteTestimonial(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/delete-testimonial?tid=${payload.testimonialId}`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getHotSongCard(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-actual-hot-album",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function deleteHotAlbum(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-song?hotAlbumId=${payload.hotSongId}`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.HOT_ALBUMS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getTopChartCard(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/top-chart-list",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function deleteTopChart(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-top-chart?chartId=${payload.chartId}`,
      {},
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.TOP_CHARTS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getFeedback(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-user-feedbacks-list",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editProfile(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/edit-admin-profile`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editProfilePicure(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/upload-profile-picture`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.AUTH.SHOW_PROFILE);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function displayLyrics(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/get-admin-lyrics`,
      payload,
      config
    );
    if (status === 200 && data) {
      // toast.success(data.message)
      return data;
    } else {
      // toast.error(data.message)
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addHotSongs(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-hot-album`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.HOT_ALBUMS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addHotAlbums(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-actual-hot-album`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.HOT_ALBUMS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function albumSongs(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-album-songs?album_id=${payload.album_id}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function artistSongs(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/artist/song?artistId=${payload.artistId}&page=${payload.page}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      return false;
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteActualHotAlbum(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-actual-hot-album?albumId=${payload.hotSongId}`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.HOT_ALBUMS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

/** V2 APIs */
async function getTrendingArtists(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-trending-artists`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getRecommendedArtists(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-recommended-artists`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getSearchArtist(payload = {}, config = {}, navigation) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/search-artist`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTrendingArtist(payload, config, navigation) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-trending-artist`,
      payload,
      config
    );

    if (status === 201 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data?.message || "Failed to add trending artist.");
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addRecommendedArtist(payload, config, navigation) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-recommended-artist`,
      payload,
      config
    );

    if (status === 201 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data?.message || "Failed to add trending artist.");
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteTrendingArtist(payload, config, navigate) {
  try {
    // Make the DELETE request with ID in the body and headers
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-trending-artist`, // No ID in the URL
      payload, // Send the ID in the request body
      config
    );

    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message); // Show error if the status is not 200
    }
  } catch (error) {
    handleApiError(error); // Handle any API errors
  }
}

async function deleteRecommendedArtist(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-recommended-artist`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getTopExploreAlbums(config) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-top-explore-albums`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getSearchAlbum(payload = {}, config = {}, navigation) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/search-album`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTopAlbum(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-top-album`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.HOT_ALBUMS);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteTopExploreAlbum(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-top-explore-album`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getAllAdminPlaylist(payload, config, navigation) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-all-admin-playlist`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getPlaylistSong(payload, config, navigation) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-playlist-songs`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getSongRecommendedList(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-recommended-song-list`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getPopularSongs(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/most-popular-song-list`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getSearchSong(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/search-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
      return [];
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addMostPopularSongs(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-most-popular-song`,
      payload,
      config
    );
    if (status === 201 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteMostPopularSongs(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-popular-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function createAdminPlaylist(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/create-admin-playlist`,
      payload,
      config
    );
    if (status === 201 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function uploadPlaylistImage(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/upload-playlist-picture`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addRecommendedSong(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-recommended-song`,
      payload,
      config
    );
    if (status === 201 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteRecommendedSong(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-recommended-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getMostPlayedSong(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-most-played-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addMostPlayedSong(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-most-played-song`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteMostPlayedSong(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-most-played-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteAdminPlaylist(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-admin-playlist`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function patchRemoveAdminPlaylistSong(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "PATCH",
      `/admin/remove-admin-playlist-song`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function patchUpdateAdminPlaylist(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "PATCH",
      `/admin/update-admin-playlist`,
      payload,
      config
    );
    if (status === 201 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getGenres(config) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-genres`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addSongToAdminPlayList(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-song-to-playlist`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getAllQuizs(config = {}, payload, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `admin/get-all-quizzes`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function uploaduizCsv(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `admin/add-quiz`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getContactus(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-contact-us",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addQuiz(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `admin/add-single-quiz`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getTopArtists(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-top-artists",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTopArtist(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `admin/add-top-artist`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteTopArtist(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `admin/delete-top-artist`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getTopSong(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-top-songs",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addTopSong(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `admin/add-top-song`,
      payload,
      config
    );
    if (status === 201 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteTopSong(payload, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `admin/delete-top-songs`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getNewReleaseSong(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/admin/get-top-songs",
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getAllContributeToLyrics(config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `admin/get-all-lyrics`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getLyricsById(config = {}, lyricsId, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `admin/get-lyrics-by-id?lyrics_id=${lyricsId}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editArtistBio(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/edit-artist-bio`,
      payload,
      config
    );
    if (status === 200 && data) {
      toast.success(data.message);
      navigate(ROUTE_CONSTANT.COMMON.ARTIST_BIO);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getAllPlaylistBio(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `admin/artist-bio-list`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getArtistBioDetails(config = {}, artistId, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `user/get-artist-bio-with-top?artist_id=${artistId}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function uploadNewsImage(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/upload-news-avatar`,
      payload,
      config
    );

    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function putUpdateNews(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      `/admin/update-news`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function uploadAuthorImage(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/upload-author-picture`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function approveOrrejectLyrics(payload, config, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/process-contribution`,
      payload,
      config
    );
    if (status === 201 && data) {
      navigate(ROUTE_CONSTANT.COMMON.CONTRIBUTE_TO_LYRICS);
      return status;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getNewsSubscriber(config = {}, page) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-all-news-latter?page=${page}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getArtistSongAndAlbums(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-artist-songs-albums?artist_id=${payload.artist_id}`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getBanners(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-banners`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addBanner(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/create-banner`,
      payload,
      config
    );
    if (status === 201 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editBanner(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/update-banner`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteBanner(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-banner`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function toggleStatusFun(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/update-status`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getYoutubeVideos(config = {}, payload) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      `/admin/get-all-youtube-videos`,
      {},
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getSearchYoutubeVideos(payload = {}, config = {}, navigation) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/search-youtube-video`,
      payload,
      config
    );
    if (status === 200 && data) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function addYoutubeVideo(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      `/admin/add-youtube-video`,
      payload,
      config
    );
    if (status === 201) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function deleteYoutubeVideo(payload, config) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      `/admin/delete-youtube-video`,
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    handleApiError(error);
  }
}

export const allAPiServicesCall = {
  loginAdmin,
  getProfileDetails,
  editProfile,
  editProfilePicure,
  changePassword,
  forgetPassword,
  resetPassword,
  getNews,
  getNewsDetails,
  addNews,
  addNewsCoverPic,
  deleteNews,
  addTestimonial,
  addTestimonialProfilePic,
  getTestimonialList,
  deleteTestimonial,
  getHotSongCard,
  deleteHotAlbum,
  getTopChartCard,
  deleteTopChart,
  getFeedback,
  displayLyrics,
  addHotSongs,
  albumSongs,
  artistSongs,
  addHotAlbums,
  deleteActualHotAlbum,
  /**V2 API Services */
  addMostPopularSongs,
  deleteMostPopularSongs,
  createAdminPlaylist,
  getTrendingArtists,
  getRecommendedArtists,
  getSearchArtist,
  addTrendingArtist,
  addRecommendedArtist,
  deleteTrendingArtist,
  deleteRecommendedArtist,
  getTopExploreAlbums,
  getSearchAlbum,
  addTopAlbum,
  deleteTopExploreAlbum,
  getAllAdminPlaylist,
  getPlaylistSong,
  getSongRecommendedList,
  getPopularSongs,
  getSearchSong,
  addRecommendedSong,
  deleteRecommendedSong,
  getMostPlayedSong,
  addMostPlayedSong,
  deleteMostPlayedSong,
  deleteAdminPlaylist,
  patchRemoveAdminPlaylistSong,
  patchUpdateAdminPlaylist,
  uploadPlaylistImage,
  getGenres,
  addSongToAdminPlayList,
  getAllQuizs,
  uploaduizCsv,
  getContactus,
  addQuiz,
  getTopArtists,
  addTopArtist,
  deleteTopArtist,
  getTopSong,
  addTopSong,
  deleteTopSong,
  getNewReleaseSong,
  getAllPlaylistBio,
  getAllContributeToLyrics,
  getLyricsById,
  editArtistBio,
  getArtistBioDetails,
  uploadNewsImage,
  putUpdateNews,
  uploadAuthorImage,
  approveOrrejectLyrics,
  getNewsSubscriber,
  getArtistSongAndAlbums,
  getBanners,
  addBanner,
  editBanner,
  deleteBanner,
  toggleStatusFun,
  getYoutubeVideos,
  getSearchYoutubeVideos,
  addYoutubeVideo,
  deleteYoutubeVideo,
};
